import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../../components/Common/Layout";
import CompanyLogo from "../../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../../components/Website/ProjectsNavigation";
import ServicePellet from "../../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../../components/Website/ProjectImagesSwiper";

const BroadwayApp = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      title="Brocode Studio || Broadway Podcasts"
      description="Broadway Podcasts"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Broadway Podcasts</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <p class="wow move-up mb--20 d-md-block d-none">
                    Broadway Podcast Network is all about creating an engaging,
                    immersive, user-friendly experience where theatre stories of
                    all kinds can be easily found, shared, and enjoyed. Broadway
                    Podcast Network is building the perfect hub that includes
                    tremendously talented content creators, all-star hosts,
                    producers, writers, industry leaders, and storytellers of
                    all kinds.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[15]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block mx-0">
              <p class="wow move-up mb--20">
                Broadway Podcast Network is all about creating an engaging,
                immersive, user-friendly experience where theatre stories of all
                kinds can be easily found, shared, and enjoyed. Broadway Podcast
                Network is building the perfect hub that includes tremendously
                talented content creators, all-star hosts, producers, writers,
                industry leaders, and storytellers of all kinds.
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4 d-none d-md-flex">
              <Col md={4} xs={12}>
                <Img fluid={imagesData[0]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[1]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[2]} />
              </Col>
            </Row>
            <ProjectImagesSwiper
              imagesList={imagesData.filter((img, id) => id !== 15)}
            />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4 d-none d-md-flex">
              <Col md={4} xs={12}>
                <Img fluid={imagesData[3]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[4]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[5]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4 d-none d-md-flex">
              <Col md={4} xs={12}>
                <Img fluid={imagesData[6]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[7]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[8]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4 d-none d-md-flex">
              <Col md={4} xs={12}>
                <Img fluid={imagesData[9]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[10]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[11]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4 d-none d-md-flex">
              <Col md={4} xs={12}>
                <Img fluid={imagesData[12]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[13]} />
              </Col>
              <Col md={4} xs={12}>
                <Img fluid={imagesData[14]} />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="broadwayapp" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Houses To Share",
              link: "/work/housestoshare",
            }}
            prevProject={{ name: "Martins Wine", link: "/work/martinswine" }}
            selectedLanguage="pt"
          />
        </div>
      </div>
    </Layout>
  );
};

export default BroadwayApp;

export const BroadwayAppQuery = graphql`
  query BroadwayAppPhotosPt {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/broadwayapp/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
